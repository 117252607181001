import isFluid from '@aurora/shared-utils/helpers/styles/FluidHelper';
import type { HTMLAttributes } from 'react';
import React, { useContext } from 'react';
import type { ContainerProps } from 'react-bootstrap';
import { useClassNameMapper, Container } from 'react-bootstrap';
import ThemeContext from '../../context/ThemeContext/ThemeContext';
import localStyles from './ThemedContainer.module.pcss';

type CustomAttributes = Omit<HTMLAttributes<HTMLElement>, 'children'> & {
  /**
   * Custom attribute used to apply styles for a specific theme.
   */
  'data-lia-styles'?: String;
  /**
   * Custom attribute used for testing.
   */
  'data-testid'?: String;
};

interface Props extends ContainerProps, CustomAttributes {
  /**
   * A React reference to the container
   */
  containerRef?: React.Ref<HTMLDivElement>;
  /**
   * Ignores the varible `--lia-grid-gutter-width-xs` on smallest
   * breakpoint.
   */
  ignoreXsGutters?: Boolean;
}

/**
 * A replacement for the Bootstrap Container that respects Theme settings
 *
 * @author Rosalyn Rowe
 */
const ThemedContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  fluid,
  containerRef,
  ignoreXsGutters = false,
  ...attributes
}) => {
  const cx = useClassNameMapper(localStyles);
  const { theme } = useContext(ThemeContext);

  const isContainerFluid = fluid || isFluid(theme?.basics?.maximumWidthOfPageContent);

  return (
    <Container
      className={cx(
        className,
        {
          'lia-fixed-container': !isContainerFluid
        },
        {
          'lia-fixed-gutters': ignoreXsGutters
        }
      )}
      data-testid="ThemedContainer"
      fluid={isContainerFluid}
      ref={containerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      {children}
    </Container>
  );
};

export default ThemedContainer;
