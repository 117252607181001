import React from 'react';
import type { QuiltWrapperSectionType } from '../../pageeditor/QuiltWrapperRenderer/types';

export interface QuiltWrapperWidgetLocation {
  /**
   * The id of the section where the widget is located
   */
  sectionId: QuiltWrapperSectionType;
  /**
   * The index of the widget in the section where it is located
   */
  widgetIdx: number;
}

/**
 * Context applicable to any component in end user application within a quilt wrapper section
 * @author Luisina Santos
 */
const QuiltWrapperWidgetLocationContext = React.createContext<QuiltWrapperWidgetLocation>(null);

export default QuiltWrapperWidgetLocationContext;
