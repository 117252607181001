import type { WidgetLocation } from '@aurora/shared-client/components/context/SectionWidgetContext';
import type { SectionEditLevel } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type React from 'react';
import type { WidgetProps } from '../../components/common/Widget/types';
import type { QuiltWrapperEditorSelection } from '../../components/context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import type { NodeBannerProps } from '../../components/community/NodeBannerWidget/useNodeBannerWidgetFinalProps';
import type { EditedQuilt } from '../../components/pageeditor/PageBuilder/types';
import type { QuiltWrapperWidget } from '../../components/pageeditor/QuiltWrapperRenderer/types';
import type { QuiltTemplatePublishedViewFragment } from '../../types/graphql-types';

export enum WidgetEditLevel {
  /**
   * allows the user to reposition, configure, and delete a widget
   */
  FULL = 'FULL',
  /**
   * removes the ability to delete a widget, but allows for widget configuration and movement within the section.
   * The widget cannot be dragged to another section. If the widget does not have editor defined, then will only allow
   * movement of widget.
   */
  CONFIGURE = 'CONFIGURE',
  /**
   * completely removes controls for the widget
   */
  LOCKED = 'LOCKED'
}

export interface PlaceholderSectionWidgetData {
  /**
   * The page editor selection type
   */
  type: PageEditorSelectionType.SECTION_WIDGET_PLACEHOLDER;
  /**
   * The id of the section where the widget is being added
   */
  sectionId: string;
  /**
   * The id of the section column where the widget is being added
   */
  columnId: string;

  /**
   * The edit level of the section
   */
  sectionEditLevel: SectionEditLevel;
}

export enum PageEditorSelectionType {
  SECTION = 'section',
  SECTION_WIDGET = 'widget',
  SECTION_WIDGET_PLACEHOLDER = 'placeholder',
  BANNER_WIDGET = 'banner_widget'
}

export interface WidgetData {
  /**
   * The page editor selection type
   */
  type: PageEditorSelectionType;

  /**
   * The id of the widget as specified in the page quilt
   */
  widgetId: string;
}

export interface BannerWidgetData extends WidgetData {
  type: PageEditorSelectionType.BANNER_WIDGET;
  props: NodeBannerProps;
}

export interface SectionWidgetData extends WidgetData {
  type: PageEditorSelectionType.SECTION_WIDGET;

  location: WidgetLocation;

  props: WidgetProps;

  sectionEditLevel: SectionEditLevel;
}

export type PageEditorSelectionData =
  | SectionData
  | SectionWidgetData
  | PlaceholderSectionWidgetData
  | BannerWidgetData;

export interface SectionData {
  /**
   * The page editor selection type
   */
  type: PageEditorSelectionType.SECTION;
  /**
   * The id of the section as specified in the page quilt
   */
  sectionId: string;
}

/**
 * Returns true if the selection data is of type SectionData
 * @param selectionData
 */
export function isPageEditorSection(
  selectionData: PageEditorSelectionData
): selectionData is SectionData {
  return selectionData?.type === PageEditorSelectionType.SECTION;
}

/**
 * Returns true if the selection data is of type SectionWidgetData
 * @param selectionData
 */
export function isPageEditorSectionWidget(
  selectionData: PageEditorSelectionData
): selectionData is SectionWidgetData {
  return selectionData?.type === PageEditorSelectionType.SECTION_WIDGET;
}

/**
 * Returns true if the selection data is of type BannerWidget
 * @param selectionData
 */
export function isPageEditorBannerWidget(
  selectionData: PageEditorSelectionData
): selectionData is BannerWidgetData {
  return selectionData?.type === PageEditorSelectionType.BANNER_WIDGET;
}

/**
 * Returns true if the specified location is a widget placeholder location
 * @param selectionData
 */
export function isPageEditorSectionWidgetPlaceholder(
  selectionData: PageEditorSelectionData
): selectionData is PlaceholderSectionWidgetData {
  return selectionData?.type === PageEditorSelectionType.SECTION_WIDGET_PLACEHOLDER;
}

/**
 * constant used to identify the custom dispatchPageEditorHighlight event.
 */
export const liaPageEditorHighlightEvent = 'lia-page-editor-highlight';

/**
 * Function that dispatches a custom 'lia-page-editor-hover' event.
 *
 * @param event the event
 * @param selectionId the id of the section or page header
 * @param add whether to add the style or remove it
 * @param dangerColor whether or not the section highlight should use Bootstrap's $danger color.
 */
export function dispatchPageEditorHighlight(
  event: React.MouseEvent | React.FocusEvent,
  selectionId: WidgetLocation['sectionId'],
  add = true,
  dangerColor = false
): void {
  const selectionHighlightEvent = new CustomEvent(liaPageEditorHighlightEvent, {
    detail: { event, selectionId, add, dangerColor },
    bubbles: true
  });
  event.target.dispatchEvent(selectionHighlightEvent);
}

/**
 * Returns true if the specified selection is a quilt wrapper widget
 * @param selection the selection
 */
export function isQuiltWrapperWidget(
  selection: QuiltWrapperEditorSelection
): selection is QuiltWrapperWidget {
  return selection ? !!selection['location'] : false;
}

function replaceQuiltContainer(editedQuilt: EditedQuilt, quiltWrapperId: string): EditedQuilt {
  const result = deepClone(editedQuilt);
  result.quilt.container.id = quiltWrapperId;

  return result;
}

export function getEditedQuiltsOnDeleteQuiltWrapper(
  editedQuilts: EditedQuilt[],
  finalAssociatedPages: Array<{
    defaultQuiltId: string;
    templateId?: string;
  }>,
  publishedAssociatedPages: Array<QuiltTemplatePublishedViewFragment>,
  quiltWrapperReplacementId: string
): EditedQuilt[] {
  const quiltsToUpdate: EditedQuilt[] = [];
  const adjustedSessionTemplates = deepClone(editedQuilts);
  finalAssociatedPages.forEach(associatedPage => {
    const templateInSession = editedQuilts.find(
      editedQuilt =>
        editedQuilt.pageId === associatedPage.defaultQuiltId &&
        editedQuilt.templateId === associatedPage.templateId
    );
    if (templateInSession) {
      const pageReplacement = replaceQuiltContainer(templateInSession, quiltWrapperReplacementId);
      const indexTemplate = editedQuilts.findIndex(
        editedQuilt =>
          editedQuilt.pageId === templateInSession.pageId &&
          editedQuilt.templateId === templateInSession.templateId
      );
      if (indexTemplate !== -1) {
        adjustedSessionTemplates.splice(indexTemplate, 1, pageReplacement);
      } else {
        quiltsToUpdate.push(pageReplacement);
      }
    } else {
      const publishedQuiltTemplate = publishedAssociatedPages.find(
        template =>
          template.defaultQuiltId === associatedPage.defaultQuiltId &&
          template.templateId === associatedPage.templateId
      );
      quiltsToUpdate.push(
        replaceQuiltContainer(
          {
            pageId: EndUserPages[publishedQuiltTemplate.defaultQuiltId],
            templateId: publishedQuiltTemplate.templateId,
            currentQuilt: publishedQuiltTemplate.quiltResource.quilt,
            quilt: publishedQuiltTemplate.quiltResource.quilt,
            deleteOverride: false,
            editedTexts: [],
            associatedNodes: publishedQuiltTemplate.coreNodes,
            contextNodeId: publishedQuiltTemplate.context?.contextCoreNode?.id
          },
          quiltWrapperReplacementId
        )
      );
    }
  });

  return [...adjustedSessionTemplates, ...quiltsToUpdate];
}
